import Web3 from "web3";
import axios from "axios";
import {toast} from "react-toastify";
import {ENV} from "../config/config";

export const getContractMethods = async (abiJson: any, contract: any) => {
    let web3 = await getWeb3();
    const contractM = new web3.eth.Contract(abiJson, contract);
    return contractM;
};

export const getChainId = async () => {
    if (window?.ethereum) {
        let web3 = await getWeb3();
        let chainId = await web3.eth.getChainId();
        return chainId;
    }
};

type Network = {
    name: string;
    rpcUrls: string[];
    blockExplorerUrls: string[];
};

export const getNetwork = (chainId: number): Network => {
    const networks = {
        1442: {
            name: "Polygon zkEVM Testnet",
            rpcUrls: ["https://rpc.public.zkevm-test.net"],
            blockExplorerUrls: ['https://testnet-zkevm.polygonscan.com/']
        },
        1101: {
            name: "Polygon zkEVM",
            rpcUrls: ['https://zkevm-rpc.com', 'https://rpc.polygon-zkevm.gateway.fm', 'https://rpc.ankr.com/polygon_zkevm', 'https://1rpc.io/zkevm'],
            blockExplorerUrls: ['https://zkevm.polygonscan.com/']
        },
        5: {
            name: "Goerli Testnet",
            rpcUrls: ['https://goerli.infura.io/v3/6b2f6f0d6c5e4b6e8b3b0b0b0b0b0b0b'],
            blockExplorerUrls: ['https://goerli.etherscan.io/']
        },
        1: {
            name: "Ethereum Mainnet",
            rpcUrls: ['https://mainnet.infura.io/v3/6b2f6f0d6c5e4b6e8b3b0b0b0b0b0b0b'],
            blockExplorerUrls: ['https://etherscan.io/']
        },
    };
    // @ts-ignore
    return networks[chainId];
};

export const isChainConfigured = async (chainId: number) => {
    if (window?.ethereum) {
        try {

            const chainData = await window.ethereum.request({
                method: 'eth_chainId'
            });
            // @ts-ignore
            const currentChainId = parseInt(chainData, 16);

            return currentChainId === chainId;
        } catch (error) {
            console.error('Error occurred while checking chain configuration:', error);
        }
    } else {
        console.error('MetaMask is not installed or accessible.');
    }

    return false;
}

export const setupNewNetwork = async (chainId: number) => {
    if (window?.ethereum) {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: Web3.utils.toHex(chainId),
                        chainName: getNetwork(chainId).name,
                        nativeCurrency: {
                            name: 'Ether',
                            symbol: 'ETH',
                            decimals: 18
                        },
                        rpcUrls: getNetwork(chainId).rpcUrls,
                        blockExplorerUrls: getNetwork(chainId).blockExplorerUrls
                    }
                ]
            });
        } catch (error) {
            console.error('Error occurred while adding new chain:', error);
        }
    } else {
        console.error('MetaMask is not installed or accessible.');
    }
}

export const switchNetworkToChainId = async (chainId: number) => {
    if (window?.ethereum) {
        if (await isChainConfigured(chainId) === false) setupNewNetwork(chainId);
        try {
            let web3 = await getWeb3();
            await (web3.currentProvider as any).request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: Web3.utils.toHex(chainId)}],
            });
            let accounts = await web3.eth.getAccounts();
            return accounts[0];
        } catch (err: any) {}
    }
};

export const getMetamaskConnectedAddress = async (): Promise<string | undefined> => {
    let web3 = await getWeb3();

    // Error when not installing metamask
    let account;
    try {
        let accounts = await web3.eth.getAccounts();
        account = accounts[0];
    } catch (e) {
    }

    return account
};

// Get web3 instance
export const getWeb3 = async () => {
    let instance;

    if (window?.ethereum) {
        try {
            await window.ethereum.request({method: 'eth_requestAccounts'});
        } catch (e) {}
        instance = new Web3(Web3.givenProvider);
    } else {
        let network = getNetwork(ENV.chainId);
        instance = new Web3(network.rpcUrls[0]);
    }

    return instance;
};

export const connectWithMetamask = async () => {
    try {
        let web3 = await getWeb3();
        if (window.ethereum)
            await window?.ethereum.request({
                method: "eth_accounts",
                params: [
                    {
                        eth_accounts: {},
                    },
                ],
            });
        let accounts = await web3.eth.getAccounts();
        return accounts[0];
    } catch (err: any) {
        toast.error(err?.message);
    }
};

type GasStationGasPrices = {
    mainnet: string;
    testnet: string;
    zkevm: string;
    testnetzkevm: string;
};

const GAS_STATION_GAS_PRICES: GasStationGasPrices = {
    mainnet: "https://gasstation-mainnet.matic.network/v2",
    testnet: "https://gasstation-mumbai.matic.today/v2",
    zkevm: "https://gasstation.polygon.technology/zkevm",
    testnetzkevm: "https://gasstation-testnet.polygon.technology/zkevm",
};

const getEstimatedGasPriceFromGasStationPolygon = async (network: keyof GasStationGasPrices) => {
    try {
        const gasPriceFromStation = await axios({
            method: "get",
            // @ts-ignore
            url: GAS_STATION_GAS_PRICES[network],
        });

        if (gasPriceFromStation?.data) {
            return gasPriceFromStation.data.fast;
        }
        return false;
    } catch (err) {
        console.error('Error retrieving gas price:', err);
        return false;
    }
};

export const getGasPriceFromStation = async (web3: any, chainId: any) => {
    try {

        const gasPriceByStation = await getEstimatedGasPriceFromGasStationPolygon(
            chainId === 1101 ? 'zkevm' : 'testnetzkevm'
        );

        if (gasPriceByStation) {
            const gasPriceInGwei = await web3.utils.toWei(
                gasPriceByStation.toFixed(5),
                "gwei"
            );
            return gasPriceInGwei;
        } else {
            throw new Error("Error while fetching gas price from station");
        }

    } catch (err) {
        console.log(
            "🚀 ~ file: contractHelpers.ts ~ line 26 ~ getGasPriceFromStation ~ err",
            err
        );

        throw err;
    }
};
