import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDataContext} from "../../context/context";
import cx from "classnames";
import {FiChevronDown} from "react-icons/fi";
import {HiOutlineLogout} from "react-icons/hi";
import {GiHamburgerMenu} from "react-icons/gi";
import {MdClose} from "react-icons/md";
import styles from "./Header.module.css";
import BackOfficeButton from "./BackOfficeButton";
// @ts-ignore
import {Container} from "../pages/FrontPage/FrontPageComponents/container/Container";
import Button from "../Button/Button";
// @ts-ignore
import {
    connectWithMetamask,
    getChainId,
    getMetamaskConnectedAddress,
    switchNetworkToChainId
} from "../../utilities/web3.utility";
import {decryptData, handleRequest} from "../../utilities/common.utility";
import {ENV} from "../../config/config";
import {toast} from "react-toastify";
import {isMobile} from "react-device-detect";
// @ts-ignore
import profileIcon from "../../images/profile.svg";
import axios from "axios";

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const { connect, setConnect }:any= useDataContext();
    const { backOffice }:any = useDataContext();
    const [dropdown, setDropdown] = useState(false);
    const [active, setActive] = useState<any>(null);
    const [sidebar, setSidebar] = useState(false);
    const [showBackOffice, setShowBackOffice] = useState<any>(false);
    const [showLogout, setShowLogout] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState<any>(false);
    const { pathname } = useLocation();

    const navArray = [
        {
            navItem: "Dashboard",
            to: "/dashboard",
        },
        {
            navItem: "Marketplace",
            to: "/marketplace",
        },
        {
            navItem: "Staking & Claim",
            to: "/stake",
        },
    ];

    const getWalletAddress = async () => {
        const address = await getMetamaskConnectedAddress();
        if (address) {
            try {
                await authenticateUser(address)
            } catch (err:any) {}
        }
    };

    const showDropDown = (index:number) => {
        setDropdown((prev) => !prev);
        setActive(index);
    };

    useEffect(() => {
        getWalletAddress().catch();
    }, []);

    useEffect(() => {
        if (connect) {
            isAdmin();
        }
        // @ts-ignore
        window?.ethereum?.on("chainChanged", async () => {
            if ( await validateNetwork()) {
                getWalletAddress().catch();
            } else {
                onDisconnect();
            }
        });
    }, [connect]);

    const validateNetwork = async () => {
        const chainId = await getChainId();
        return !(ENV.chainId !== chainId || !connect);
    };

    useEffect(() => {
        if (menuVisible) {
            document.body.classList.add("overflow-hidden", "lg:overflow-auto");
        } else {
            document.body.classList.remove("overflow-hidden", "lg:overflow-auto");
        }
    }, [menuVisible]);

    const authenticateUser = async (walletAddress:any) => {
        // @ts-ignore
        const response = await handleRequest("get", "user/authenticate", {} as any, { walletAddress });

        const authenticated = response.data;
        if (authenticated?.success) {
            const decryptedUser = decryptData(authenticated.user);
            if (isAdmin(walletAddress)) {
                ENV.encryptUserData({
                    isAdmin: true,
                    authToken: authenticated?.token,
                    ...decryptedUser,
                });
                window.localStorage.setItem("isAdmin", String(true));
            } else {
                ENV.encryptUserData({
                    isAdmin: false,
                    authToken: authenticated?.token,
                    ...decryptedUser,
                });
            }
            window.localStorage.setItem("authToken", authenticated?.token);
            const chainId = await getChainId();
            if (ENV.chainId !== Number(chainId)) {
                await switchNetworkToChainId(ENV.chainId);
            }
            setConnect(walletAddress);
        }
    };

    const connectHandler = async () => {
        try {
            if (connect) {
                return setShowLogout(!showLogout);
            }
            // @ts-ignore
            if (isMobile && !window?.ethereum) {
                return window.open("https://metamask.app.link/dapp/www.owna.io/");
            }
            // @ts-ignore
            if (!window?.ethereum) {
                return toast.error("Please install metamask extention");
            }
            setIsConnecting(true);

            const chainId = await getChainId();
            if (ENV.chainId !== Number(chainId)) {
                await switchNetworkToChainId(ENV.chainId);
            }
            let address = await connectWithMetamask();
            setIsConnecting(false);
            if (address) authenticateUser(address);
        } catch (err:any) {
            setIsConnecting(false);
            toast.error(err.message);
        }
    };

    const onDisconnect = () => {
        setAvatarUrl(false);
        setShowLogout(false);
        setConnect("");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("authToken");
        localStorage.removeItem("encuse")
    };

    const isAdmin = (address?:any) => {
        let connectedAddress = address ? address?.toUpperCase() : connect?.toUpperCase();
        if ( ENV.admin){
            setShowBackOffice(
                ENV.admin
                    .split(", ")
                    .map((x) => x.toUpperCase())
                    .includes(connectedAddress)
            );
            return ENV.admin
                .split(", ")
                .map((x) => x.toUpperCase())
                .includes(connectedAddress);
        }
    };

    useEffect(() => {
        if (connect) {
            const getAvatar = async (walletAddress:any) => {
                return axios.get('https://api.ensideas.com/ens/resolve/' + walletAddress)
                .then(response => {
                    return response.data.avatar;
                })
                .catch(error => {
                    console.log(error);
                });
            }
            const fetchAvatar = async (connect?:any) => {
                const avatarURI = await getAvatar(connect);
                setAvatarUrl(avatarURI);
            };
            fetchAvatar(connect).catch();
        }
    }, [connect]);

    return (
        <Container>
            <header className={`${pathname === "/" && " Header"} py-3 px-0`}>
                <nav
                    className={cx({
                        "h-screen md:h-auto": menuVisible,
                    })}
                >
                    {" "}
                    <section className={styles.header}>
                        <Link to="/" rel="noreferrer" className={styles.logo}>
                            Owna
                            <span className={styles.dot} />
                        </Link>

                        <div className={`${styles.navWrapper} ${sidebar && styles.sidebar}`}>
                            {navArray.map((item, i) => (
                                <div className={styles.navItemsAndIcon} onClick={() => {
                                    showDropDown(i);
                                    setSidebar(false);
                                }} key={i}>
                                    <div key={i} className={styles.navItems} onMouseEnter={() => showDropDown(i)} onMouseLeave={() => showDropDown(i)}>
                                        <Link style={{ textDecoration: "none" }} to={item.to}>
                                            <p className={styles.navLink}>{item.navItem}</p>
                                        </Link>
                                      
                                    </div>
                                </div>
                            ))}
                            {sidebar && backOffice && showBackOffice && <BackOfficeButton sidebar={sidebar} />}
                        </div>
                        <div className={styles.addressContainer}>
                            <Link to={ pathname == "/myProfile" ? "/marketplace" : "/myProfile"}>
                                {avatarUrl ? <img src={avatarUrl} alt="avatar" className={styles.avatar} /> : <img src={profileIcon} alt="avatar" className={styles.avatar} />}
                            </Link>
                            {backOffice && showBackOffice && <BackOfficeButton />}

                            <div className={styles.connectContainer}>
                                {" "}
                                <Button
                                    background={"black"}
                                    disabled={isConnecting}
                                    btnStyles={{
                                        borderRadius: "10px",
                                        padding: "10px 30px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    click={connectHandler}
                                >
                                    <span className={styles.connectBtnContentContainer}>
                                        {connect ? connect?.slice(0, 6) + "..." + connect?.slice(-6) : "Connect Wallet"}
                                        {connect && <FiChevronDown style={{ marginLeft: "0.2rem", color: "white" }} className={styles.arrow} />}
                                    </span>
                                </Button>
                                {showLogout && (
                                    <div className={styles.logoutDropdown} onMouseLeave={() => setShowLogout(false)} onClick={() => onDisconnect()}>
                                        <HiOutlineLogout className={styles.logoutIcon} />
                                        <span className={styles.logoutText}>Disconnect</span>
                                    </div>
                                )}
                            </div>
                            {sidebar ? <MdClose className={styles.icon} onClick={() => setSidebar((prev) => !prev)} /> : <GiHamburgerMenu className={styles.icon} onClick={() => setSidebar((prev) => !prev)} />}
                        </div>
                    </section>
                </nav>
            </header>
        </Container>
    );
};
export default Header;
